




































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "register-company-validate" as string,

  props: {
    openModal: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
    messages: Array as PropType<Array<string>>,
  },

  watch: {
    openModal(newVal) {
      if (newVal) {
        this.$refs.myModalRef.show();
      } else {
        this.$refs.myModalRef.hide();
      }
    },
  },
});
