

















































































































































































































































































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/register.vue";
import RegisterCompanyValidate from "@/components/modals/RegisterCompanyValidate.vue";
import { required, email, maxLength } from "vuelidate/lib/validators";
import _ from "lodash";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import { CompanyRegisterData } from "@/types";
import { checkVAT, countries } from "jsvat";
import router from "@/router";
import RegistrationStepper from "@/components/registration-stepper.vue";
import ArrowForward from "@iconify-icons/ion/arrow-forward";
import ArrowBack from "@iconify-icons/ion/arrow-back";
import { successMessage } from "@/utils/messages-handlers";

export default Vue.extend({
  components: {
    Layout,
    vSelect,
    "register-company-validate": RegisterCompanyValidate,
    RegistrationStepper,
  },

  data() {
    return {
      currentStep: 4,
      arrowLeftBlue: require("@/assets/images/arrow-left-blue.svg"),
      businessName: "",
      legalEntityName: "",
      address: "",
      city: "",
      zipCode: "",
      countries: [],
      selectedCountry: null,
      ico: "",
      vat: "",
      contactPerson: "",
      contactPersonPhone: "",
      contactPersonEmail: "",
      isSubmitted: false,
      icon: {
        arrowNext: ArrowForward,
        arrowBack: ArrowBack,
      },
      selectedVenueType: null,
      showSkVenues: false,
      skVenueTypes: [],
      validateModal: false,
      validateMessages: [] as Array<string>,
      sendLoader: false,
    };
  },

  //@ts-ignore
  validations: {
    //businessName: { required },
    legalEntityName: { required },
    address: { required },
    city: { required },
    zipCode: { required, maxLength: maxLength(10) },
    selectedCountry: { required },
    ico: { required },
    contactPerson: { required },
    contactPersonPhone: {
      checkPhoneNumber(contactPersonPhone) {
        if (contactPersonPhone === null || contactPersonPhone === "")
          return true;
        return /^[+\d](?:.*\d|\s*)(?:.*\d|\s*)?$/.test(contactPersonPhone);
      },
    },
    contactPersonEmail: { required, email },
    vat: {
      checkVat(vatId) {
        return this.checkVatId(vatId);
      },
    },
  },

  async mounted() {
    await this.initRegistrationData();
    this.setInitRegister();
    await this.setPageInitialState();
    await this.setCountries();
    this.countries = this.getCountries;
    //this.fetchSelectedCountry();
    this.contactPersonEmail = this.userData.email
      ? this.userData.email
      : this.loggedUser.email;
    this.contactPerson = this.userData.first_name
      ? this.userData.first_name + " " + this.userData.last_name
      : this.loggedUser.first_name + " " + this.loggedUser.last_name;
    this.selectedCountry = "";
    this.track();
  },

  created() {
    this.interval = setInterval(() => this.checkIfEmailWasVerified(this.userEmail), 10000);
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },

  computed: {
    ...mapGetters("venueModule", {
      getCountries: "GET_COUNTRY_CODES",
    }),
    ...mapGetters("registerModule", {
      existCompanyWithVat: "EXIST_COMPANY_WITH_VAT",
      existCompanyWithName: "EXIST_COMPANY_WITH_NAME",
      existCompanyWithBin: "EXIST_COMPANY_WITH_BIN",
      isSearching: "GET_VENUES_SEARCHING",
      userEmail: "GET_USER_EMAIL",
      userData: "GET_USER_DATA",
      venueCountryCode: "GET_VENUE_COUNTRY_CODE",
      venueGoogleData: "GET_VENUE_GOOGLE_DATA",
      venueSlovenskoDigitalData: "GET_VENUES_SLOVENSKO_DIGITAL_DATA",
      venueRegisterData: "GET_VENUE_DATA",
      companyRegisterData: "GET_COMPANY_DATA"
    }),
    ...mapGetters("userModule", {
      loggedUser: "GET_LOGGED_USER",
    }),
    ...mapGetters("emailsModule", {
      isUserEmailVerified: "GET_IS_USER_EMAIL_VERIFIED",
    }),
    formatZipCode: {
      get() {
        return this.zipCode;
      },
      set(value: any) {
        this.zipCode = value.toUpperCase();
      },
    },
    canGoBack() {
       const isUnfinished: string | null = localStorage.getItem("unfinishedRegistration");

       return isUnfinished === "true";
    }
  },

  methods: {
    ...mapActions("userModule", {
      saveCompanyBillingData: "SAVE_COMPANY_BILLING_DATA",
    }),
    ...mapActions("venueModule", {
      setCountries: "SET_COUNTRY_CODES",
    }),
    ...mapActions("registerModule", {
      setCompany: "SET_COMPANY",
      finishRegistration: "REGISTER",
      filterCompanyVat: "FILTER_COMPANY_VAT",
      filterCompanyName: "FILTER_COMPANY_NAME",
      filterCompanyBin: "FILTER_COMPANY_BIN",
      getSlovakEntityData: "GET_SLOVENSKO_DIGITAL_DATA",
      processClientZoneRegistration: "PROCESS_CLIENTZONE_REGISTRATION",
      resetCompanyFilters: "RESET_COMPANY_FILTERS",
      setInitRegister: "SET_INIT_REGISTER",
      setRegistrationDataAfterRedirection: "SET_REGISTER_DATA_AFTER_REDIRECTION",
    }),
    ...mapActions("emailsModule", {
      checkifIsUserActive: "IS_USER_ACTIVE",
      sendEmail: "SEND_REGISTRATION_EMAIL",
    }),
    continueToStepFour() {
      router.push("/register/step-4?lang=" + this.$i18n.locale);
    },

    async initRegistrationData(): Promise<void> {
      await this.checkIfEmailWasVerified(this.userEmail);
      const registrationData: string | null = localStorage.getItem("registrationData");

      if (registrationData != null) {
        return;
      }

      const userId: string | null = this.$route.query.user;

      if (userId == null) {
        this.$router.push("/register/step-1");
        return;
      }

      const registerData = {
        companyData: {
          business_type_id: this.companyRegisterData.business_type_id,
          email: this.companyRegisterData.email,
        },
        loggedUser: {},
        newUserId: parseInt(localStorage.getItem("clientZoneUserId")),
        registrationResourceId: userId,
        userData: this.userData,
        userIsLoggedIn: false,
        venueData: this.venueRegisterData
      };

      localStorage.setItem("registrationData", JSON.stringify(registerData));
    },

    fetchSelectedCountry() {
      if (this.venueCountryCode) {
        const filteredCountry = this.countries.filter(
          country => country.code === this.venueCountryCode.toLowerCase()
        );
        this.selectedCountry = !!filteredCountry[0] ? filteredCountry[0] : null;
      } else {
        this.selectedCountry = null;
      }
    },
    async continueToStepSix(): Promise<void> {
      this.isSubmitted = true;

      if (this.isUserEmailVerified === false) {
        return;
      }

      this.sendLoader = true;

      this.resetCompanyFilters();
      this.validateModal = false;
      this.validateMessages = [];
      this.$v.$touch();

      if (this.$v.$invalid || !this.checkVatId(this.vat)) {
        this.sendLoader = false;
        return this.$scrollTo(1);
      }

      if (this.vat) {
        if (this.vat.length > 0) {
          await this.filterCompanyVat(this.vat.trim());
        }
      }

      await this.filterCompanyName(this.businessName.trim());
      await this.filterCompanyBin(this.ico.trim());

      if (
        this.existCompanyWithVat ||
        this.existCompanyWithName ||
        this.existCompanyWithBin
      ) {
        this.validateModal = true;
        if (this.existCompanyWithVat) {
          this.validateMessages.push(
            `${this.$t("Company with VAT")} ${this.vat} ${this.$t(
              "is already registered."
            )}`
          );
        }
        if (this.existCompanyWithName) {
          this.validateMessages.push(
            `${this.$t("Company with name")} ${this.businessName} ${this.$t(
              "is already registered."
            )}`
          );
        }
        if (this.existCompanyWithBin) {
          this.validateMessages.push(
            `${this.$t("Company with business identification number")} ${
              this.ico
            } ${this.$t("is already registered.")}`
          );
        }
        this.sendLoader = false;
        return;
      }

      const companyPayload: CompanyRegisterData = {
        name: this.businessName,
        legal_entity_name: this.legalEntityName,
        billing_street: this.address,
        billing_city: this.city,
        billing_zip: this.zipCode,
        billing_country_id: this.selectedCountry.id,
        billing_ico: this.ico,
        billing_vat: this.vat ?? "",
        billing_email: this.contactPersonEmail,
        contact: this.contactPerson,
        phone: this.contactPersonPhone,
        email: this.userEmail,
      };

      this.setCompany(companyPayload);

      await this.processClientZoneRegistration();
      this.sendLoader = false;
    },

    checkVatId(vatId) {
      if (vatId == null) {
        return true;
      }
      let vatObject = checkVAT(vatId, countries);
      if (vatId.length == 0) {
        return true;
      } else {
        return vatObject.isValidFormat;
      }
    },

    openSkVenues() {
      this.showSkVenues = true;
    },

    closeSkVenues() {
      this.showSkVenues = false;
    },

    searchSkVenues: _.debounce(async function () {
      const inputSearch: string = this.legalEntityName;
      const icoSearch: string = this.ico;

      // if (inputSearch.length > 2 && this.ico == "") {
      if (inputSearch.length > 2 || icoSearch.length > 2) {
        let searchPayload = {};
        searchPayload = {
          name: inputSearch,
          ico: icoSearch,
        };
        await this.getSlovakEntityData(searchPayload);

        if (this.venueSlovenskoDigitalData.length) {
          this.openSkVenues();
        } else {
          this.closeSkVenues();
        }
      }
    }, 500),

    setSkVenue(index) {
      let venue = this.venueSlovenskoDigitalData[index];

      this.legalEntityName = venue.name;
      this.address = venue.formatted_street;
      this.city = venue.municipality;
      this.zipCode = venue.postal_code;
      this.ico = venue.cin;
      this.vat = venue.vatin;
      if (venue.country == "Slovenská republika") {
        this.selectedCountry = {
          code: "sk",
          id: 1,
          name: "Slovakia",
        };
      }
      this.closeSkVenues();
    },

    sendEmailAgain() {
      const userData = this.userData;

      this.sendEmail({
        email: userData.email,
        clientZoneUserId: parseInt(localStorage.getItem("clientZoneUserId")),
        userIsLoggedIn: false,
      });
      successMessage(`${this.$t('We have sent a verification link to your email address')}`, 2000);

      setTimeout(() => {
        this.sendLoader = false;
      }, 750);
    },

    async checkIfEmailWasVerified(email: string): Promise<void> {
      if (email) {
        await this.checkifIsUserActive(email);
      }

      if (this.isUserEmailVerified === true) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },

    async setPageInitialState() {
      await this.setRegistrationDataAfterRedirection();
    },

    track() {
      this.$gtag.event("registration", {
        event_category: "registration",
        event_label: "step",
        value: "5",
      });
    },
  },
  watch: {
    legalEntityName: function (newVal, oldVal) {
      if (this.businessName == "" || this.businessName == oldVal) {
        this.businessName = newVal;
      }
    },
  },
});
